// Include all function that need to be run on page load

console.log('premier-additions is active');

document.addEventListener("DOMContentLoaded", function () {

    console.log('doc ready - premier-additions is active');
    layoutContainerContentWrapper()
    setImageBackground()
    createButtonGroups()

});