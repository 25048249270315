/**
 * Function to find an image with the class of .image-background and set it to the background of the parent container.
 * Should run inside a DOMContentLoaded event listener.
 *
 * @example
 * setImageBackground()
 *
 */

function setImageBackground() {
  // get all the elements with the class of .image-background
  const imageBackgrounds = document.querySelectorAll('.user_mode .layoutContainer.image-bg')

  // loop through each element
  imageBackgrounds.forEach(function (imageBackground) {
    // get the image url from the element
    const imgURL = 'url(' + imageBackground.querySelector('.pageEl.image-bg img').getAttribute('src') + ')'

    // set the background image of the parent container
    imageBackground.style.backgroundImage = imgURL
  })
}
