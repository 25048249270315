/**
 *   Puts layoutContainer child items inside a wrapper to improve styling options
 *
 * @example
 * contentWrapper()
 *
 */

function layoutContainerContentWrapper() {
  // const layoutOutContainers = document.querySelectorAll('.user_mode div.layoutContainer.content-width')
  const layoutOutContainers = document.querySelectorAll('.user_mode div.layoutContainer:not(.layout-20-20-20-20-20)')

  layoutOutContainers.forEach((container) => {
    //console.log('wrap')
    const wrapper = document.createElement('div')
    wrapper.classList.add('layoutContainer-ct-wrap')

    // Move all child nodes into the wrapper
    while (container.firstChild) {
      wrapper.appendChild(container.firstChild)
    }

    // Append the wrapper back to the container
    container.appendChild(wrapper)
  })
}
