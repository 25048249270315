function createButtonGroups() {
    // Find all layout containers
    document.querySelectorAll('.layoutContainer').forEach(container => {
        // Find all button items in this container
        const buttons = container.querySelectorAll('.user_mode .pageEl[class*="bttn-"]');
        if (!buttons.length) return;
        
        // Track groups of adjacent buttons
        let currentGroup = [];
        
        // Process all buttons
        for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            
            // If we don't have a group yet or this button is adjacent to the last one in the group
            if (currentGroup.length === 0 || 
                currentGroup[currentGroup.length-1].nextElementSibling === button) {
                currentGroup.push(button);
            } else {
                // This button isn't adjacent to our group
                // If we have at least 2 buttons, wrap them
                if (currentGroup.length >= 2) {
                    wrapButtonGroup(currentGroup);
                }
                // Start a new group with this button
                currentGroup = [button];
            }
        }
        
        // Wrap any remaining group with at least 2 buttons
        if (currentGroup.length >= 2) {
            wrapButtonGroup(currentGroup);
        }
    });
}

function wrapButtonGroup(buttons) {
    // Create wrapper
    const wrapper = document.createElement('div');
    wrapper.className = 'bttn-group';
    
    // Insert wrapper before first button
    buttons[0].parentNode.insertBefore(wrapper, buttons[0]);
    
    // Move all buttons into wrapper
    buttons.forEach(button => wrapper.appendChild(button));
}